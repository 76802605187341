.parent {
  width: 100%;
  display: flex;
}

.sidebar {
  width: 17.5%;
}

.subparent {
  width: 82.5%;
  position: relative;
}

.greenStatus {
  background-color: #c9ffc2c5;
  color: rgb(78, 143, 78);
  font-size: 14px;
  border-radius: 100px;
  width: 110px;
  font-weight: bold;
  height: 30px;
  padding-top: 6px;

}
.greenStatus p {
  margin-top: 0px !important;
}
.redStatus{
  background-color: #ffdadac5;
  color: rgb(218, 59, 59);
  font-size: 14px;
  border-radius: 100px;
  width: 110px;
  font-weight: bold;
  height: 30px;
  padding-top: 6px;
}
.redStatus p {
  margin-top: 0px !important;
}

.searchbar {
  display: flex;
  margin: auto;
  width: 97%;
  height: 62px;
  padding: 1px 16px 1px 0px;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid var(--foundation-blue-b-30, #eff1f4);
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 80px;
}

.sec1 {
  display: flex;
  width: 425px;
  height: 46px;
  padding: 12px 0px 12px 15px;
  align-items: flex-start;
  gap: 10px;
}

@media (min-width: 750px) {
  .sec1>img {
    margin-left: 1rem;
  }
}

.sec1>input {
  color: var(--foundation-grey-g-900, #333);
  text-align: left;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  outline: 0;
  width: 90%;
}

.sec2 {
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  width: 154px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;
}

.textLight {
  color: rgb(151, 150, 150) !important;
}

.sec2>p {
  color: #2b5ce6;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tableParent {
  overflow: scroll;
  width: 93%;
  height: 620px;
  margin: auto;
  margin-top: 13px;
  margin-left: 18px;
  user-select: none;
}

.tables {
  display: flex;
  width: 2275px;
}

.table {
  width: 100%;
  position: relative;
  z-index: 0;
}

.headers {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--foundation-blue-b-10, #fafbfb);
}

.headers>td {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--foundation-blue-b-500, #42526d);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.headers>td:nth-child(1) {
  width: 165px;
}

.headers>td:nth-child(2) {
  width: 254px;
}

.headers>td:nth-child(3) {
  width: 225px;
}

.headers>td:nth-child(4) {
  width: 224px;
}

.headers>td:nth-child(5) {
  width: 224px;
}

.headers>td:nth-child(6) {
  width: 224px;
}

.headers>td:nth-child(7) {
  width: 224px;
}

.tablebody>td:nth-child(1) {
  width: 165px;
}

.tablebody>td:nth-child(2) {
  width: 254px;
}

.tablebody>td:nth-child(3) {
  width: 225px;
}

.tablebody>td:nth-child(4) {
  width: 224px;
}

.tablebody>td:nth-child(5) {
  width: 224px;
}

.tablebody>td:nth-child(6) {
  width: 224px;
}

.tablebody>td:nth-child(7) {
  width: 224px;
}

.tablebody>td {
  display: flex;
  height: 72px;
  padding: 12px 24px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.tablebody {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
}

.txtStyle1 {
  color: var(--foundation-blue-b-900, #091e42);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.txtStyle2 {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.txtStyle4 {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.txtStyle3 {
  color: #62c554;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.txtStyle5 {
  color: var(--foundation-blue-b-200, #6b788e);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tablebody>td:nth-child(1)>p {
  padding: 2px 8px 2px 6px;
  border-radius: 16px;
  background: var(--foundation-blue-b-20, #f5f6f7);
}

.viewBtn {
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  background: #fff;
  color: #2b5ce6;
  cursor: pointer;
}

.viewBtn:hover {
  background-color: #2b5ce6;
  color: #fff;
}

.downloadBtn {
  color: #ee6a5f;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 86px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ee6a5f;
  background: #fff;
}

.downloadBtn:hover {
  background-color: #ee6a5f;
  color: #fff;
}

.next {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 71px;
  margin-right: 31px;
  margin-bottom: 28px;
}

.next>button {
  width: 108px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #707070;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
}

.next>button:hover {
  background-color: #7e7e7e;
  color: #fff;
}

.mybtn {
  padding: 3px 10px !important;
  margin-top: 5px;
}

@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }

  .subparent {
    width: 80%;
  }
}

@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }

  .subparent {
    width: 75%;
  }
}

@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }

  .subparent {
    width: 70%;
  }
}

@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }

  .subparent {
    width: 100%;
  }

  .searchbar {
    margin-top: 80px;
    padding: 1px 0px 1px 0px;
  }
}

.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}

.alert {
  width: 438px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

.msg {
  margin-top: 25px;
  margin-left: 37px;
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.alertbtns {
  margin-top: 84px;
  margin-left: 165px;
}

@media (max-width: 500px) {
  .alert {
    width: 95%;
  }

  .alertbtns {
    margin-top: 74px;
    margin-left: 37px;
  }
}

.btn1 {
  width: 111px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.inputDate {
  width: 100% !important;
  padding-right: 0% !important;
}

.btn2 {
  width: 111px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}

.headers {
  height: 88px;
  color: var(--foundation-blue-b-500, #42526d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-bottom: 1px solid #eaecf0;
  background: var(--foundation-blue-b-10, #fafbfb);
}

.table {
  width: 544px;
}

.body {
  border-bottom: 1px solid #eaecf0;
}



.headers>th {
  width: 120px;
  padding: 0% 24px;
}

.body>td {
  width: 120px;
  padding: 0% 24px;
}

/* .body > td {
  height: 72px;
  padding: 16px 24px;
  gap: 12px;
  align-self: stretch;
} */
.body p {
  display: flex;
  padding: 2px 8px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--foundation-blue-b-20, #f5f6f7);
  color: var(--foundation-blue-b-900, #091e42);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  width: 43px;
  height: 22px;
}

.txtStyle2 {
  color: var(--gray-900, #101828);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.bodyrow td {
  font-family: Poppins;
  font-size: small;
}

.bodyrow td p {
  margin-top: 18px;

}

.my-custom-table tbody tr {
  margin-bottom: 15px;
  /* Adjust this value to control the spacing */
}

.txtStyle3 {
  color: #000;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.headers {
  height: 44px;
  color: var(--foundation-blue-b-500, #42526d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
  border-bottom: 1px solid #eaecf0;
  background: var(--foundation-blue-b-10, #fafbfb);
}

.headers>th {
  width: 112px;
  text-align: center;
}

.table {
  width: 336px;
}

.body {
  border-bottom: 1px solid #eaecf0;
}

.body>td {
  height: 72px;
  width: 112px;
  padding: 16px 24px;
  gap: 12px;
  align-self: stretch;
  text-align: center;
}

.txtStyle1 {
  color: #62c554;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.txtStyle2 {
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  color: #2b5ce6;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
}

.txtStyle3 {
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ee6a5f;
  color: #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
}

.txtStyle2:hover {
  background-color: #2b5ce6;
  color: #fff;

}

.txtStyle3:hover {
  background-color: #ee6a5f;
  color: #fff;

}

.headers {
  height: 44px;
  color: var(--foundation-blue-b-500, #42526d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
  border-bottom: 1px solid #eaecf0;
  background: var(--foundation-blue-b-10, #fafbfb);
}

.headers>th {
  width: 112px;
  text-align: center;
}

.table {
  width: 336px;
}

.body {
  border-bottom: 1px solid #eaecf0;
}

.body>td {
  height: 72px;
  width: 112px;
  padding: 16px 24px;
  gap: 12px;
  align-self: stretch;
  text-align: center;
}

.txtStyle1 {
  color: #62c554;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.txtStyle2 {
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  color: #2b5ce6;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
}

.txtStyle3 {
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ee6a5f;
  color: #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
}

.txtStyle2:hover {
  background-color: #2b5ce6;
  color: #fff;

}

.txtStyle3:hover {
  background-color: #ee6a5f;
  color: #fff;

}

.font>th {
  font-family: Poppins;
  font-size: 14px;

}