.parent {
  width: 100%;
  display: flex;
}
.sidebar {
  width: 17.5%;
}
.addEmployee {
  width: 82.5%;
  margin-bottom: 1rem;
}

.form {
  width: 100%;
  margin: auto;
  margin-top: 95px;
}

.headers {
  display: flex;
  width: 90%;
  height: 68px;
  align-items: center;
  gap: 37px;
  margin: auto;
  padding-left: 32px;
  border-radius: 10px 10px 0px 0px;
  background: #ee6a5f;
}
.spans {
  display: flex;
  gap: 5px;
}

.spans > span {
  display: block;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #62c554;
}
.spans > span:nth-child(2) {
  border-radius: 10px;
  background: #f5be4f;
}
.spans > span:nth-child(1) {
  border-radius: 10px;
  background: #ee201c;
}
.para {
  color: #363636;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  width: 80% !important;
  display: flex;
}

.userform {
  margin: auto;
  border-radius: 0px 0px 10px 10px;
  background: #fafafa;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
}
.sec1 {
  width: 100%;
  margin: 89px auto 45px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.sec1 img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dropdown {
  flex-direction: column !important;
  height: auto !important;
  padding-right: 0 !important;
  margin-top: 41px;
}
.optStyle {
  color: #010101;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .dropdown > p {
  height: 30px;
  width: 100%;
  color: #010101;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-bottom: 14px;
  line-height: normal;
  padding-bottom: 14px;
  background-color: #fafafa !important;
} */

.sec1 input {
  padding: 14px 22px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  background: #fff;
  width: 90%;
  color: #898989;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sec1 > div {
  width: 40%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  padding-right: 19px;
}

@media (max-width: 1100px) {
  .sec1 > div {
    width: 70%;
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 0 !important;
  gap: 1rem;
  background: #fafafa !important;
}

.submit {
  width: 156px;
  height: 58px;
  flex-shrink: 0;
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #ee6a5f;
  border: 0;
}

.submit:hover {
  border: 1px solid #ee6a5f;
  background: #fff;
  color: #ee6a5f;
}
@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }
  .addEmployee {
    width: 80%;
  }
}
@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }
  .addEmployee {
    width: 75%;
  }
}
@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }
  .addEmployee {
    width: 70%;
  }
}
@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }
  .addEmployee {
    width: 100%;
  }
  .form {
    margin-top: 240px;
  }
}
@media (max-width: 550px) {
  .userform {
    flex-direction: column;
  }
  .sec1 {
    width: 90%;
    padding-bottom: 0px;
  }
  .sec1 > div {
    width: 100%;
  }
  .sec2 > div {
    width: 100%;
  }
}

.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}
.alert {
  width: 438px;
  height: 311px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff !important;
}

.alert textarea {
  width: 95%;
  outline: 0;
  border: 0;
  height: 201px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #d9d9d9;
  display: block;
  margin: auto;
  margin-top: 2.5%;
  padding: 14px 10px;
  color: #707070;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.alertbtns {
  margin-top: 33px;
  margin-left: 165px;
}
@media (max-width: 500px) {
  .alert {
    width: 95%;
  }
  .alertbtns {
    margin-top: 33px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn1 {
  width: 111px;
  height: 49.595px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}
.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.inputDate {
  width: 100% !important;
  padding-right: 0% !important;
}

.btn2 {
  width: 111px;
  height: 49.595px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}

.resbtns {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #fafafa;
  padding-bottom: 45px;
  flex-wrap: wrap;
}

@media (max-width: 1400px) {
  .resbtns {
    display: flex;
    border-radius: 0% 0% 10px 10px;
  }
  .btns {
    display: none !important;
  }
}

.indicator {
  display: flex;
  gap: 22px;
  cursor: pointer;
}
.indicator2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 22px;
  align-items: center;
  padding-left: 22px;
  cursor: pointer;
}
.indicator > div,
.indicator2 > div {
  border-radius: 21px;
  background: #f7f7f7;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator > div > img,
.indicator2 > div > img {
  width: 10px;
  height: 5px;
}

.droper {
  height: auto !important;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-right: 0 !important;
  transition: 3s;
}
.rotate {
  transform: rotate(180deg);
  transition: 0.3s;
}
.droper > p {
  width: 100%;
  height: 60px !important;
  padding: 16px 0px 15px 39px;
  cursor: pointer;
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.droper > p > input {
 width: 20px;
 height: 20px;
 margin-right: 1rem;
}

.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}

.alert {
  width: 438px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

.msg {
  margin-top: 25px;
  margin-left: 37px;
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.alertbtns {
  margin-top: 84px;
  margin-left: 190px;
}

.btn1 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.btn2 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}
.sec2 select{
  border: 0;
  outline: 0;
}
