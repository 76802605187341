.parent {
  width: 100%;
  display: flex;
}
.sidebar {
  width: 17.5%;
}

.form {
  width: 792px;
  margin: auto;
  margin-top: 79px;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
background: #FAFAFA;
margin-bottom: 17px;
}

.headers {
 
  width: 100%;

  height: 68px;
 
  margin: auto;

  border-radius: 10px 10px 0px 0px;
  background: #ee6a5f;
}
.spans {
  display: flex;
  gap: 5px;
}

.spans > span {
  display: block;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #62c554;
}
.spans > span:nth-child(2) {
  border-radius: 10px;
  background: #f5be4f;
}
.spans > span:nth-child(1) {
  border-radius: 10px;
  background: #ee201c;
}
.para {
  color: #363636;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  width: 80% !important;
  display: flex;
}

.profile {
  padding-top: 10px;
  background: #fafafa;
  width: 100%;
  margin: auto;
}
.profile img {
  display: block;
  margin: auto;
}
.profile > div:nth-child(2) {
  width: 40.625px;
  height: 40.625px;
  flex-shrink: 0;
  margin: auto;
  border-radius: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 3rem;
  left: 4.5rem;
  cursor: pointer;
}
.sec1 {
  width: 100%;
  background: #fafafa;
  padding-top: 0px;
}
.sec1 div {
  border-radius: 10px;
  background: #FFF;

  margin: auto;
  margin-bottom: 15px;
}
.sec1 input {
  padding: 18px 22px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  width: 90%;
}




.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 0 !important;
  gap: 1rem;
  background: #fafafa !important;
}


.btns button:nth-child(1) {
  width: 90px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #ee6a5f;
  border: 0;
}

@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }
  .form {
    width: 80%;
    width: 560px;
    height: 100%;
  }
}
@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }
  .form {
    width: 65%;
  }
}
@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }
  .form {
    width: 65%;
  }
}
@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }
  .form {
    width: 90%;
    margin-top: 275px;
    margin-bottom: 95px;
  }
}


.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}

.alert {
  width: 438px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

.msg {
  margin-top: 25px;
  margin-left: 37px;
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.alertbtns {
  margin-top: 84px;
  margin-left: 190px;
}

.btn1 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.btn2 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}