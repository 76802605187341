.parent {
  width: 100%;
  display: flex;
}

.sidebar {
  width: 17.5%;
}

.addEmployee {
  width: 82.5%;
  margin-bottom: 1rem;
}

.form {
  width: 100%;
  margin: auto;
  margin-top: 95px;
}

.headers {
  display: flex;
  width: 90%;
  height: 68px;
  align-items: center;
  gap: 37px;
  margin: auto;
  padding-left: 32px;
  border-radius: 10px 10px 0px 0px;
  background: #ee6a5f;
}

.spans {
  display: flex;
  gap: 5px;
}

.spans>span {
  display: block;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #62c554;
}

.spans>span:nth-child(2) {
  border-radius: 10px;
  background: #f5be4f;
}

.spans>span:nth-child(1) {
  border-radius: 10px;
  background: #ee201c;
}

.para {
  color: #363636;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  width: 80% !important;
  display: flex;
}

.profile {
  padding-top: 55px;
  padding-bottom: 14px;
  background: #fafafa;
  width: 90%;
  margin: auto;
}

.profile img {
  display: block;
  margin: auto;
}

.profile>div:nth-child(2) {
  width: 40.625px;
  height: 40.625px;
  flex-shrink: 0;
  margin: auto;
  border-radius: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 3rem;
  left: 4.5rem;
  cursor: pointer;
}

.userform {
  margin: auto;
  border-radius: 0px 0px 10px 10px;
  background: #fafafa;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
}

.sec1 {
  width: 45%;
  margin-left: 36px;
  padding-bottom: 65px;
}

.sec1 img,
.sec2 img {
  width: 20px;
  height: 20;
  cursor: pointer;
}

.sec1 input,
.sec2 input {
  padding: 18px 22px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  background: #fff;
  width: 90%;
}

.sec2 {
  width: 45%;
}

.sec1>div {
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-top: 41px;
  background: #fff;
  padding-right: 19px;
}

.sec2>div {
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-top: 41px;
  background: #fff;
  padding-right: 19px;
}

.indicator {
  display: flex;
  gap: 22px;
}

.indicator>div {
  border-radius: 21px;
  background: #f7f7f7;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator>div>img {
  width: 10px;
  height: 5px;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 0 !important;
  gap: 1rem;
  background: #fafafa !important;
}

.btns button:nth-child(1) {
  width: 246px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ee6a5f;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ee6a5f;
  background: #fff;
}

.pbtn{
  width: 200px;
  height: 58px;
  padding: 15px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ee6a5f;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ee6a5f;
  background: #fff;
}
.pbtn:hover{
  color: #fff;
  background: #ee6a5f;
}
.btns p:nth-child(1) {
  width: 246px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ee6a5f;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ee6a5f;
  background: #fff;
}

@media (max-width: 950px) {
 
  .btns button:nth-child(1) {
    width: 100%;
  }
  .pbtn{
    width: 100%;
  }
}

.btns button:nth-child(2) {
  width: 156px;
  height: 58px;
  flex-shrink: 0;
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #ee6a5f;
  border: 0;
}

.btns button:nth-child(1):hover {
  background: #ee6a5f;
  color: #fff;
}



.btns button:nth-child(2):hover {
  border: 1px solid #ee6a5f;
  background: #fff;
  color: #ee6a5f;
}

@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }

  .addEmployee {
    width: 80%;
  }
}

@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }

  .addEmployee {
    width: 75%;
  }
}

@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }

  .addEmployee {
    width: 70%;
  }
}

@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }

  .addEmployee {
    width: 100%;
  }

  .form {
    margin-top: 240px;
  }
}

@media (max-width: 550px) {
  .userform {
    flex-direction: column;
  }

  .sec1 {
    width: 90%;
    margin: auto;
    padding-bottom: 0px;
  }

  .sec1>div {
    width: 100%;
  }

  .sec2 {
    width: 90%;
    margin: auto;
    padding-bottom: 65px;
  }

  .sec2>div {
    width: 100%;
  }
}
.mybtn{
  padding: 10px !important;
}

.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}

.alert {
  width: 438px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

.msg {
  margin-top: 25px;
  margin-left: 37px;
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.alertbtns {
  margin-top: 84px;
  margin-left: 190px;
}

.btn1 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.btn2 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}
.sec2 select{
  border: 0;
  outline: 0;
}