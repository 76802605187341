.parent {
  width: 100%;
  display: flex;
}
.sidebar {
  width: 17.5%;
}
.subparent {
  width: 82.5%;
}

.searchbar {
  display: flex;
  margin: auto;
  width: 95%;
  padding: 1px 16px 1px 0px;
  justify-content: space-between;
  border-radius: 7px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 80px;
}

.sec1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 86px;
  flex-wrap: wrap;
  width: 80%;
}
.septrate {
  display: none;
}


@media (max-width: 500px) {
  .sec1 {
    gap: 15px !important;
    width: 100%;
  }
}
.sec1 > input {
  color: var(--foundation-grey-g-900, #333);
  text-align: left;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  outline: 0;
  width: 60%;
}

.sec2,
.sec3 {
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  width: 127px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 29px;
}
.sec2 > p,
.sec3 > p {
  color: #2b5ce6;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tableParent {
  width: 93%;
  height: 620px;
  margin: auto;
  margin-top: 13px;
  margin-left: 18px;
}
.tableParent2 {
  width: 95%;
  height: 620px;
  margin: auto;
  margin-top: 13px;
  overflow-x: scroll;
}

.table {
  width: 100%;
}
.headers {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--foundation-blue-b-10, #fafbfb);
}
.headers > td {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--foundation-blue-b-500, #42526d);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.headers > td:nth-child(1),
.tablebody > td:nth-child(1) {
  width: 270px;
}
.headers > td:nth-child(2),
.tablebody > td:nth-child(2) {
  width: 207px;
}
.headers > td:nth-child(3),
.tablebody > td:nth-child(3) {
  width: 211px;
}
.headers > td:nth-child(4),
.tablebody > td:nth-child(4) {
  width: 214px;
}
.headers > td:nth-child(5),
.tablebody > td:nth-child(5) {
  width: 218px;
}

.tablebody > td {
  display: flex;
  height: 72px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--foundation-blue-b-500, #42526d);
  color: #000;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.tablebody {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
}
.tablebody > td:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.tablebody > td:nth-child(1) > p {
  color: #2b5ce6;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.next {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 29px;
  margin-right: 31px;
  margin-bottom: 28px;
}
.next > button {
  width: 108px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #707070;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
}
@media (max-width: 1000px) {
  .septrate {
    display: block;
  }
  .sec1 {
    gap: 41px;
    width: 100%;
  }
  .sec2 {
    display: none !important;
  }
  .septrate {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}


@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }
  .subparent {
    width: 80%;
  }
}
@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }
  .subparent {
    width: 75%;
  }
}
@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }
  .subparent {
    width: 70%;
  }
}
@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }
  .subparent {
    width: 100%;
    margin-top: 40px;
  }
  .sec2 {
    margin-right: 0px;
  }
}


.btn > button {
  width: 120px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ee6a5f;
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 30px;
}
.btn > button:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.alertparent {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.7);
}

.alert {
  width: 438px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

.msg {
  margin-top: 25px;
  margin-left: 37px;
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.alertbtns {
  margin-top: 84px;
  margin-left: 190px;
}

.btn1 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ee6a5f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.btn1:hover {
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
}

.btn2:hover {
  background: #ee6a5f;
  color: #fff;
}

.btn2 {
  width: 95px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 10px;
  color: #ee6a5f;
  background: #fff;
  border: 1px solid #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 28px;
  line-height: normal;
}
.sec1 select{
  border: 1px solid red;
  border-radius: 5px;
  padding: 0.5rem;
  outline: 0;
}

.backBtn {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.backBtn button {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #837272;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #707070;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backBtn button:hover {
  color: #fff;
  background-color: #707070;
}

/*  */
.Btns {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.Btns button {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #837272;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #707070;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Btns button:hover {
  color: #fff;
  background-color: #707070;
}
