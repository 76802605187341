.parent {
    width: 100%;
    display: flex;
  }
  .sidebar {
    width: 17.5%;
  }
  .subparent {
    width: 82.5%;
    position: relative;
  }
  .headingtxt {
    color: #db5853;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 24px;
  }
  
  .searchbar {
    display: flex;
    margin: auto;
    width: 97%;
    height: 62px;
    padding: 1px 16px 1px 0px;
    justify-content: space-between;
    border-radius: 7px;
    border: 1px solid var(--foundation-blue-b-30, #eff1f4);
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    margin-top: 15px;
  }
  .redtxt {
    width: 97%;
    margin: auto;
    color: #db5853;
    text-align: left;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 80px;
  }
  
  @media (max-width: 750px) {
    .redtxt {
      margin-top: 12rem;
    }
  }
  
  .sec1 {
    display: flex;
    width: 425px;
    height: 46px;
    padding: 12px 38px 12px 15px;
    align-items: flex-start;
    gap: 10px;
  }
  
  .sec1 > img {
    margin-left: 1rem;
  }
  .sec1 > input {
    color: var(--foundation-grey-g-900, #333);
    text-align: left;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0;
    outline: 0;
    width: 60%;
  }
  
  .sec2 {
    border-radius: 8px;
    border: 1px solid #2b5ce6;
    width: 154px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .sec2 > p {
    color: #2b5ce6;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tableParent {
    overflow-x: scroll;
    width: 90%;
    height: 620px;
  
    margin: auto;
    margin-top: 13px;
    margin-left: 18px;
  }
  .tableParent2 {
    width: 490px;
    height: 620px;
    margin: auto;
    margin-top: 13px;
    margin-left: 18px;
  }
  
  /* .tableParent::-webkit-scrollbar {
      width: 1089px;
      height: 26px;
      flex-shrink: 0;
    
      border-radius: 20px;
      background: #d9d9d9;
    }
    .tableParent:-webkit-scrollbar-thumb {
      width: 597px !important;
      height: 14px !important;
      flex-shrink: 0;
      border-radius: 20px;
      background: #8b8b8b !important;
    } */
  
  .table {
    width: 100%;
    position: relative;
    z-index: 0;
  }
  .headers {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    background: var(--foundation-blue-b-10, #fafbfb);
  }
  .headers > td {
    display: flex;
    height: 44px;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--foundation-blue-b-500, #42526d);
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .headers > td:nth-child(1) {
    width: 138px;
  }
  .headers > td:nth-child(2) {
    width: 229px;
  }
  .headers > td:nth-child(3) {
    width: 156px;
  }
  .headers > td:nth-child(4) {
    width: 134px;
  }
  .headers > td:nth-child(5) {
    width: 200px;
  }
  .headers > td:nth-child(6) {
    width: 200px;
  }
  .headers > td:nth-child(7) {
    width: 156px;
  }
  .headers > td:nth-child(8) {
    width: 168px;
  }
  .headers > td:nth-child(9) {
    width: 180px;
  }
  
  .headers > td:nth-child(10) {
    width: 200px;
  }
  .click:hover {
    background-color: #2b5ce6;
    color: #fff;
  }
  .click {
    width: 117px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #2b5ce6;
    color: #2b5ce6;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tablebody > td {
    display: flex;
    height: 72px;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--foundation-blue-b-500, #42526d);
    color: #000;
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .name {
    display: flex;
    height: 72px;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--foundation-blue-b-500, #42526d);
    color: #000;
    font-size: 14px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  
  .tablebody {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
  }
  .tablebody > td:nth-child(1) {
    width: 138px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }
  .tablebody > td:nth-child(1) > p {
    padding: 2px 8px 2px 6px;
    border-radius: 16px;
    background: var(--foundation-blue-b-20, #f5f6f7);
  }
  .tablebody > td:nth-child(2) {
    width: 229px;
  }
  .tablebody > td:nth-child(3) {
    width: 156px;
  }
  .tablebody > td:nth-child(4) {
    width: 134px;
  }
  .tablebody > td:nth-child(5),
  .tablebody > td:nth-child(6) {
    width: 200px;
    color: var(--foundation-blue-b-200, #6b788e);
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .tablebody > td:nth-child(7) {
    width: 156px;
  }
  .onetwenty {
    width: 120px !important;
  }
  .tablebody > td:nth-child(8) button {
    width: 72px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #2b5ce6;
    background: #fff;
    color: #2b5ce6;
    cursor: pointer;
  }
  .tablebody > td:nth-child(8) {
    width: 168px;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tablebody > td:nth-child(9) {
    width: 180px;
  }
  .tablebody > td:nth-child(10) {
    width: 200px;
  }
  .tablebody > td:nth-child(9) button,
  .downloadD {
    color: #ee6a5f;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 86px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #ee6a5f;
    background: #fff;
  }
  .next {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 71px;
    margin-right: 31px;
    margin-bottom: 28px;
  }
  .next > button {
    width: 108px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    color: #707070;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #7e7e7e;
  }
  
  .next > button:hover {
    background-color: #7e7e7e;
    color: #fff;
  }
  
  @media (max-width: 1350px) {
    .sidebar {
      width: 20%;
    }
    .subparent {
      width: 80%;
    }
  }
  @media (max-width: 1250px) {
    .sidebar {
      width: 25%;
    }
    .subparent {
      width: 75%;
    }
  }
  @media (max-width: 950px) {
    .sidebar {
      width: 30%;
    }
    .subparent {
      width: 70%;
    }
  }
  @media (max-width: 750px) {
    .sidebar {
      width: 0%;
    }
    .subparent {
      width: 100%;
    }
  }
  
  
  .alertparent {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(217, 217, 217, 0.7);
  }
  
  .alert {
    width: 438px;
    height: 224px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
  }
  
  .msg {
    margin-top: 25px;
    margin-left: 37px;
    color: #000;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .alertbtns {
    margin-top: 84px;
    margin-left: 190px;
  }
  
  .btn1 {
    width: 95px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #ee6a5f;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0;
  }
  
  .btn1:hover {
    color: #ee6a5f;
    background: #fff;
    border: 1px solid #ee6a5f;
  }
  
  .btn2:hover {
    background: #ee6a5f;
    color: #fff;
  }
  
  .btn2 {
    width: 95px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 10px;
    color: #ee6a5f;
    background: #fff;
    border: 1px solid #ee6a5f;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 28px;
    line-height: normal;
  }
  
  /* Switch.css */
  
  .switch {
    position: relative;
    display: inline-block;
    height: 28px;
    width: 53px;
  }
  
  .switch input {
      opacity: 0;
      width: 0;
    height: 0;
  }
  
  .slider {
      border: 1px solid #E1E1E1;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F4F4F4;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: #9B9B9B;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  /* input:checked + .slider {
    background-color: #2196f3;
  } */
  
  input:checked + .slider:before {
      background-color: #62C554;
    transform: translateX(26px);
  }
  
  .round {
    border-radius: 34px;
    
  }
  
  .backBtn {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .backBtn button {
    width: 100px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #837272;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #707070;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .backBtn button:hover {
    color: #fff;
    background-color: #707070;
  }
  
  /*  */
  .Btns {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
  }
  
  .Btns button {
    width: 100px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #837272;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #707070;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Btns button:hover {
    color: #fff;
    background-color: #707070;
  }
  
  