* {
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}
p {
  margin-bottom: 0 !important;
}
.next > button {
  width: 108px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #707070;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
}

.next > button:hover{
  background-color: #7e7e7e;
  color: #fff;
}
