.parent {
  width: 100%;
  display: flex;
}
.sidebar {
  width: 17.5%;
}
.subparent {
  width: 82.5%;
  position: relative;
}
.greenStatus{
  background-color: #c9ffc2c5;
  color: rgb(78, 143, 78);
  font-size: 13px;
  border-radius: 100px;
  width: 70%;
  font-weight: bold;
  padding: 6px 0px;
  }
  .redStatus{
    background-color: #ffcdc6cc;
    color: rgb(202, 68, 63);
    font-size: 13px;
    border-radius: 100px;
    width: 70%;
    font-weight: bold;
    padding: 6px 0px;
  }

.searchbar {
  display: flex;
  margin: auto;
  width: 95%;
  height: 62px;
  padding: 1px 16px 1px 0px;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid var(--foundation-blue-b-30, #eff1f4);
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 80px;
}

.sec1 {
  display: flex;
  width: 425px;
  height: 46px;
  padding: 12px 38px 12px 15px;
  align-items: flex-start;
  gap: 10px;
}

.sec1 > img {
  margin-left: 1rem;
}
.sec1 > input {
  color: var(--foundation-grey-g-900, #333);
  text-align: left;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  outline: 0;
  width: 60%;
}

.sec2 {
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  width: 127px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;
}
.bluebtn {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 153px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #2b5ce6;
  margin-right: 18px;
  border: 0;
}

.sec2 > p {
  color: #2b5ce6;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tableParent {
  width: 95%;
  height: 620px;

  overflow-x: scroll;
  margin: auto;
  margin-top: 13px;
}
.tableParent2 {
  width: 99%;
  height: 620px;
  margin: auto;
  margin-top: 13px;
  margin-left: 18px;
}

.table {
  width: 600px;
  position: relative;
  z-index: 0;
}
.headers {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--foundation-blue-b-10, #fafbfb);
}
.headers > td {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--foundation-blue-b-500, #42526d);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.headers > td:nth-child(n) {
  width: 200px;
}

.tablebody > td:nth-child(n) {
  width: 200px;
}


.tablebody > td {
  display: flex;
  height: 72px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.tablebody {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
}
.bluebtn {
  background: #2b5ce6;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 153px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  margin-right: 18px;
  border: 0;
}
.bluebtn:hover {
  background-color: #fff;
  color: #2b5ce6;
  border: 1px solid #2b5ce6;
}
.orangebtn {
  width: 126px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ee6a5f;
  color: #ee6a5f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
}
.orangebtn:hover {
  background-color: #ee6a5f;
  color: #fff;
}
.bluebtn:hover {
  background-color: #fff;
  color: #2b5ce6;
  border: 1px solid #2b5ce6;
}

.next {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px !important;
  margin-right: 31px;
  margin-bottom: 111px !important;
  width: 90%;
  margin: auto;
}
.next > button {
  width: 108px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #707070;
  font-size: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
}
.next > button:hover{
  background-color: #7e7e7e;
  color: #fff;
}
.viewBtn {
  width: 72px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #2b5ce6;
  color: #2b5ce6;
  background: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.viewBtn:hover  {
  background-color: #2b5ce6;
  color: #fff;
}

.textStyle3 {
  color: var(--foundation-blue-b-200, #6b788e);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.textStyle2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.textStyle1 {
  color: #2b5ce6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.viewBtn:hover {
  background: #2b5ce6;
  color: #fff;
}
@media (max-width: 1350px) {
  .sidebar {
    width: 20%;
  }
  .subparent {
    width: 80%;
  }
}
@media (max-width: 1250px) {
  .sidebar {
    width: 25%;
  }
  .subparent {
    width: 75%;
  }
}
@media (max-width: 950px) {
  .sidebar {
    width: 30%;
  }
  .subparent {
    width: 70%;
  }
}
@media (max-width: 750px) {
  .sidebar {
    width: 0%;
  }
  .subparent {
    width: 100%;
  }
  .searchbar {
    margin-top: 80px;
  }
}


.backBtn {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.backBtn button {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #837272;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #707070;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backBtn button:hover {
  color: #fff;
  background-color: #707070;
}

/*  */
.Btns {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.Btns button {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #837272;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #707070;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Btns button:hover {
  color: #fff;
  background-color: #707070;
}
